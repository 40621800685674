body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.switcherToggleBtn{display:none !important;}

/*theme switcher scroll bar*/
.hYWOCO{
  direction: rtl !important;
  padding-right: 10px !important;
  width: 10rem !important;
  height:100vh !important;
}
.hYWOCO .SwitcherBlockWrapper{
  direction: ltr !important;
  padding-right: 18px;
}
.sc-hHLeRK.lczqWG.isoThemeSwitcher.active {
  width: 152px !important;
  height: 100% !important;
}
::-webkit-scrollbar {
  width: 0px;
}
.hYWOCO::-webkit-scrollbar{
  width:0px !important;

}


.mapContent{
  width: 100% !important;
  height: 100vh !important;
  overflow-y: scroll !important;
}

.mapContentViewer{
  width: 100% !important;
  height: 100vh !important;
  overflow-y: scroll !important;
}
.mapContent::-webkit-scrollbar{
  width: 12px !important;}

ul.ant-menu.isoDashboardMenu.ant-menu-dark.ant-menu-root.ant-menu-inline::-webkit-scrollbar {
  width: 12px !important;
}
ul.ant-menu.isoDashboardMenu.ant-menu-dark.ant-menu-root.ant-menu-inline{
  height: 100vh;
  overflow-y: auto !important;
}
.hhNAsc {
  width: 15.3% !important;
  height: 100% !important;
}
.hhNAsc .SwitcherBlockWrapper {
  padding-right: 28px !important;
}
.sc-hHLeRK.lczqWG.isoThemeSwitcher.active {
  right: 3px !important;
}
html > body > div:first-of-type > div > div:first-of-type > section > section > div > div > svg{
  display: none !important;
}
.legendSec::-webkit-scrollbar{
  width: 12px;
}
/*.legendSec{*/
/*  width: 125px;*/
/*}*/
.tileLayer::-webkit-scrollbar{
  width: 12px;
}
#gtick1{
  display: none;
}
#gtick2{
  display: none;
}
.ant-divider-dashed {
  background: none !important;
  border: dashed rgb(85, 85, 85) !important;
  border-width: 1px 0 0 !important;
}
#gtick3{
  display: none;
}
.legendSec div{
  text-align: left !important;
}
/*  margin-left: 1.5rem !important;*/
/*}*/
td.ant-table-cell p {
  color: black !important;
}
h1.isoComponentTitle.tablehead {
  margin-top: -30px !important;
}
.exportBtn{
  visibility: hidden;
}
html > body > div:first-of-type > div > div:first-of-type > section > section > section > main > div:first-of-type > div:nth-of-type(2) > div > div > div:nth-of-type(2) > div:nth-of-type(2) > div > div > h1 {
  margin-top: 2rem !important;
}
.commonBtn{
  margin-top: 0.5rem !important;
  margin-left: 11px;
}
h1.isoComponentTitle.tablehead span {
  color: black !important;
}

.tippy-box{
  margin-right: 1.5rem;
}
.sc-hHLeRK.lczqWG.isoThemeSwitcher.active {
  width: 16.3% !important;
  height: 100% !important;
}

/*right basemap layer checkbox background color fix (Start)*/
.checkbox{
  -webkit-appearance: none;
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 2.5px;
  background: white;
  border: 1px solid #E0E0E0;
  cursor: pointer;
}
.checkbox:checked{
  background-color: #00f541;
  border: 1px solid #00f541;
}

.checkbox::before{
  content: '';
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-image: url("./assets/images/icon/whitet.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.checkbox::before{
display: none;
}
.checkbox:checked::before{
  display: block;
}

/*right basemap layer checkbox background color fix (End)*/

/*sidebar css start*/

/*sidebar css end*/

